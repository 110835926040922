export const numerosString = {
    '0': 'zero',
    '1': 'um',
    '2': 'dois',
    '3': 'tres',
    '4': 'quatro',
    '5': 'cinco',
    '6': 'seis',
    '7': 'sete',
    '8': 'oito',
    '9': 'nove'
};

export const especiesAnexoProtesto = [
    'CAF',
    'CAR',
    'CFB',
    'CM',
    'CT',
    'CTI',
    'CD',
    'DD',
    'NP'
];

export default class SenhaService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }
    redefinirSenha() {
        return this._http.get('alterar_senha/');
    }

    salvarSenha(password, email, id_user, cd_apresentante) {
        const data = {
            password: password,
            email: email,
            id_user: id_user,
            cd_apresentante: cd_apresentante
        };
        return this._http.post('salvar_nova_senha/', data);
    }
}
